// BEGIN: Layout Header
var LayoutHeader = function () {
    var offset = parseInt($('.c-layout-header').attr('data-minimize-offset') > 0 ? parseInt($('.c-layout-header').attr('data-minimize-offset')) : 0);
    var _handleHeaderOnScroll = function () {
        if ($(window).scrollTop() > offset) {
            $("body").addClass("c-page-on-scroll");
        } else {
            $("body").removeClass("c-page-on-scroll");
        }
    }

    var _handleTopbarCollapse = function () {
        $('.c-layout-header .c-topbar-toggler').on('click', function (e) {
            $('.c-layout-header-topbar-collapse').toggleClass("c-topbar-expanded");
        });
    }

    return {
        //main function to initiate the module
        init: function () {
            if ($('body').hasClass('c-layout-header-fixed-non-minimized')) {
                return;
            }

            _handleHeaderOnScroll();
            _handleTopbarCollapse();

            $(window).scroll(function () {
                _handleHeaderOnScroll();
            });
        }
    };
}();
// END


$(document).ready(function () {
    // init layout handlers
    LayoutHeader.init();

    $(".fancybox").fancybox({
        helpers:  {
            thumbs : {
                width: 50,
                height: 50
            }
        },
        padding: 5,
    });

        $('.navbar a.dropdown-toggle').on('click', function(e) {
            var $el = $(this);
            var $parent = $(this).offsetParent(".dropdown-menu");
            $(this).parent("li").toggleClass('open');

            if(!$parent.parent().hasClass('nav')) {
                $el.next().css({"top": $el[0].offsetTop, "left": $parent.outerWidth() - 4});
            }

            $('.nav li.open').not($(this).parents("li")).removeClass("open");

            return false;
        });
  


});


